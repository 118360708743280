<script lang="ts" setup>
const { isOpen, currentComp, currentCompProps, closeDialog, isFullscreen } = useDialog()

const dialog = ref<HTMLElement | null>(null)

defineProps<{
  hasBackground?: boolean;
}>()

const dynamicComponent = computed(() => {
  if (currentComp.value) {
    return resolveComponent(currentComp.value)
  }
  return null
})

// close dialog on esc key
onKeyStroke('Escape', () => {
  closeDialog()
})

// Close dialog if route changes
const router = useRouter()
watch(router.currentRoute, () => {
  closeDialog()
})

// close when your clicks outisde of the dialog element
onClickOutside(dialog, () => closeDialog())
</script>

<template>
  <Teleport to="body">
    <Transition name="fade">
      <div
        v-if="isOpen"
        ref="dialog"
        :class="['dialog', {
          'is-fullscreen': isFullscreen,
          'has-background': hasBackground
        }]"
      >
        <div class="dialog-content">
          <span class="close-button">
            <MPButton
              icon="ri-close-line"
              size="medium"
              aria-label="Close dialog"
              link
              @click="closeDialog"
            />
          </span>
          <component
            :is="dynamicComponent"
            v-if="currentComp"
            v-bind="{...currentCompProps}"
          />
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<style lang="postcss" scoped>
@import '@/assets/css/breakpoints.pcss';

.fade-enter-active,
.fade-leave-active {
  transition: opacity 5s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.dialog {
  all: unset;
  position: fixed;
  z-index: 9000;
  width: 100vw;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 0.5rem;
  max-width: 100vw;
  max-height: 100vh;
  background-color: var(--main-background-color);
  overflow-y: scroll;
  scrollbar-width: none;
  box-shadow: var(--shadow-lg);
  border: 1px solid var(--color-slate-50);
  &::-webkit-scrollbar {
    display: none;
  }

  @mixin screen sm {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: fit-content;
    height: fit-content;
  }

}

.dialog.is-fullscreen {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  transform: none;
  border-radius: 0;
}

.dialog.has-background {
  background-color: rgba(0, 0, 0, 0.5);
}

.dialog-content {
  position: relative;
  padding: 1.5rem 1rem;
  width: 100%;
  height: 100%;
}

.close-button {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
