import revive_payload_client_2YXYkBQXc1 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.13.2_eslint@8.57.1_rollup@4.34.6_sass@1.84.0_typescript@5.7.3_vite@6.1.0_vue-tsc@2.2.0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_uOQrXHeewI from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.13.2_eslint@8.57.1_rollup@4.34.6_sass@1.84.0_typescript@5.7.3_vite@6.1.0_vue-tsc@2.2.0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_9fWGVYA1LO from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.13.2_eslint@8.57.1_rollup@4.34.6_sass@1.84.0_typescript@5.7.3_vite@6.1.0_vue-tsc@2.2.0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_4C8Erk5HUQ from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+supabase@1.4.6/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import _0_siteConfig_64S2BAohbh from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@3.0.6_vite@6.1.0_vue@3.5.13/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import navigation_repaint_client_B4DApAlGCO from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.13.2_eslint@8.57.1_rollup@4.34.6_sass@1.84.0_typescript@5.7.3_vite@6.1.0_vue-tsc@2.2.0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Y5gtm0qKcH from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.13.2_eslint@8.57.1_rollup@4.34.6_sass@1.84.0_typescript@5.7.3_vite@6.1.0_vue-tsc@2.2.0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_rQt8wiXcUh from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.13.2_eslint@8.57.1_rollup@4.34.6_sass@1.84.0_typescript@5.7.3_vite@6.1.0_vue-tsc@2.2.0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_jtH3VrqHlF from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.13.2_eslint@8.57.1_rollup@4.34.6_sass@1.84.0_typescript@5.7.3_vite@6.1.0_vue-tsc@2.2.0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import documentDriven_g5tmvPntdj from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+content@2.13.4_nuxt@3.15.4_vue@3.5.13/node_modules/@nuxt/content/dist/runtime/legacy/plugins/documentDriven.js";
import siteConfig_uTBv3QnkA4 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@6.0.8_rollup@4.34.6_vite@6.1.0_vue@3.5.13/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_4cHqjL1BvY from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@6.0.8_rollup@4.34.6_vite@6.1.0_vue@3.5.13/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_Na7kEUlzTP from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@6.0.8_rollup@4.34.6_vite@6.1.0_vue@3.5.13/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import defaultsWaitI18n_5qffvSu0Fe from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@6.0.8_rollup@4.34.6_vite@6.1.0_vue@3.5.13/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaultsWaitI18n.js";
import preview_client_ooG3jeyevT from "/opt/buildhome/repo/node_modules/.pnpm/@nuxthq+studio@2.2.1/node_modules/@nuxthq/studio/dist/runtime/plugins/preview.client.js";
import switch_locale_path_ssr_AJNpg9ZvUk from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.34.6_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_wkb2gfk169 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.34.6_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import scrollTo_VfGcxQgQRt from "/opt/buildhome/repo/app/plugins/scrollTo.ts";
import stripe_AAICqGovyW from "/opt/buildhome/repo/app/plugins/stripe.ts";
import sentry_h2FqLeojBn from "/opt/buildhome/repo/app/plugins/sentry.ts";
import fernand_jBadTnQrDK from "/opt/buildhome/repo/app/plugins/fernand.ts";
import posthog_cSgRgsM4qp from "/opt/buildhome/repo/app/plugins/posthog.ts";
export default [
  revive_payload_client_2YXYkBQXc1,
  unhead_uOQrXHeewI,
  router_9fWGVYA1LO,
  supabase_client_4C8Erk5HUQ,
  _0_siteConfig_64S2BAohbh,
  navigation_repaint_client_B4DApAlGCO,
  check_outdated_build_client_Y5gtm0qKcH,
  chunk_reload_client_rQt8wiXcUh,
  components_plugin_KR1HBZs4kY,
  prefetch_client_jtH3VrqHlF,
  documentDriven_g5tmvPntdj,
  siteConfig_uTBv3QnkA4,
  inferSeoMetaPlugin_4cHqjL1BvY,
  titles_Na7kEUlzTP,
  defaultsWaitI18n_5qffvSu0Fe,
  preview_client_ooG3jeyevT,
  switch_locale_path_ssr_AJNpg9ZvUk,
  i18n_wkb2gfk169,
  scrollTo_VfGcxQgQRt,
  stripe_AAICqGovyW,
  sentry_h2FqLeojBn,
  fernand_jBadTnQrDK,
  posthog_cSgRgsM4qp
]