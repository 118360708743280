import posthog from 'posthog-js'

export default defineNuxtPlugin(() => {
  const runtimeConfig = useRuntimeConfig()
  const posthogClient =
    posthog.init(runtimeConfig.public.posthogPublicKey, {
      api_host: runtimeConfig.public.posthogHost,
      person_profiles: 'always',
      debug: import.meta.dev,
      capture_pageview: false, // we add manual pageview capoture them via the metrics middleware
      persistence: 'memory', // no need for a cookie https://posthog.com/tutorials/cookieless-tracking
      session_recording: {
        maskAllInputs: true,
        maskTextSelector: '*',
        maskTextFn(text) {
          // A simple email regex - you may want to use something more advanced
          const emailRegex = /(\S+)@(\S+\.\S+)/g
          return text.trim().replace(emailRegex, (match, g1, g2) => {
            // Replace each email with asterisks - ben@posthog.com becomes ***@***********
            return '*'.repeat(g1.length) + '@' + '*'.repeat(g2.length)
          })
        }
      }
  })

  const router = useRouter()
  router.beforeEach((to, from) => {
    if (from.path !== to.path) {
      posthog.capture('$pageleave')
    }
  })

  return {
    provide: {
      posthog: () => posthogClient
    }
  }
})
