
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as document_45drivenGAx8X1TVj1Meta } from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+content@2.13.4_nuxt@3.15.4_vue@3.5.13/node_modules/@nuxt/content/dist/runtime/pages/document-driven.vue?macro=true";
import { default as accountySftgjtTmBMeta } from "/opt/buildhome/repo/app/pages/account.vue?macro=true";
import { default as atlasDQWCsSZrWlMeta } from "/opt/buildhome/repo/app/pages/atlas.vue?macro=true";
import { default as authbxTnSt5g6VMeta } from "/opt/buildhome/repo/app/pages/auth.vue?macro=true";
import { default as _91handle_93AA8ovd8NQlMeta } from "/opt/buildhome/repo/app/pages/creators/[handle].vue?macro=true";
import { default as indexuqJTBJH4AYMeta } from "/opt/buildhome/repo/app/pages/creators/index.vue?macro=true";
import { default as _91id_93m8DSLcdyKpMeta } from "/opt/buildhome/repo/app/pages/destinations/[id].vue?macro=true";
import { default as indexQzrCzG8UTkMeta } from "/opt/buildhome/repo/app/pages/destinations/index.vue?macro=true";
import { default as exploreKnSmnIDTbKMeta } from "/opt/buildhome/repo/app/pages/explore.vue?macro=true";
import { default as forgot_45passworduWqt3wQb9VMeta } from "/opt/buildhome/repo/app/pages/forgot-password.vue?macro=true";
import { default as index9xeP2sz8ZcMeta } from "/opt/buildhome/repo/app/pages/index.vue?macro=true";
import { default as copyrightRgxf2vm4oQMeta } from "/opt/buildhome/repo/app/pages/info/copyright.vue?macro=true";
import { default as creator_45compensationtC7rsYvbGeMeta } from "/opt/buildhome/repo/app/pages/info/creator-compensation.vue?macro=true";
import { default as creator_45terms2xjNyOMgpnMeta } from "/opt/buildhome/repo/app/pages/info/creator-terms.vue?macro=true";
import { default as _91spotId_93XePnlYdmuJMeta } from "/opt/buildhome/repo/app/pages/maps/[mapId]/spots/[spotId].vue?macro=true";
import { default as _91mapId_93egFDOQJyVeMeta } from "/opt/buildhome/repo/app/pages/maps/[mapId].vue?macro=true";
import { default as indexs1X8hcobF7Meta } from "/opt/buildhome/repo/app/pages/maps/index.vue?macro=true";
import { default as reset_45passwordm9IuDvKHgaMeta } from "/opt/buildhome/repo/app/pages/reset-password.vue?macro=true";
import { default as saved_45mapsj5iBmKeJNmMeta } from "/opt/buildhome/repo/app/pages/saved-maps.vue?macro=true";
import { default as saved_45spotsPhpDosRhd9Meta } from "/opt/buildhome/repo/app/pages/saved-spots.vue?macro=true";
import { default as index1jpSQ6bsa4Meta } from "/opt/buildhome/repo/app/pages/studio/admin/articles/index.vue?macro=true";
import { default as index6HrlO0SHVcMeta } from "/opt/buildhome/repo/app/pages/studio/admin/categories/index.vue?macro=true";
import { default as indexkgwoJvYB8nMeta } from "/opt/buildhome/repo/app/pages/studio/admin/creator-collab/index.vue?macro=true";
import { default as _91id_93V3fhlzlzSLMeta } from "/opt/buildhome/repo/app/pages/studio/admin/creators/[id].vue?macro=true";
import { default as indexkwBY6WtwDXMeta } from "/opt/buildhome/repo/app/pages/studio/admin/creators/index.vue?macro=true";
import { default as indexSMuohmBZkFMeta } from "/opt/buildhome/repo/app/pages/studio/admin/destinations/index.vue?macro=true";
import { default as indexD2rvASVttbMeta } from "/opt/buildhome/repo/app/pages/studio/admin/index.vue?macro=true";
import { default as indexBjPJTBsopoMeta } from "/opt/buildhome/repo/app/pages/studio/creator-faq/index.vue?macro=true";
import { default as index9jfKR8Ph8kMeta } from "/opt/buildhome/repo/app/pages/studio/maps/[mapId]/index.vue?macro=true";
import { default as _91spotId_934rvWbY7W5QMeta } from "/opt/buildhome/repo/app/pages/studio/maps/[mapId]/spots/[spotId].vue?macro=true";
import { default as _91mapId_93aGHA4LCbarMeta } from "/opt/buildhome/repo/app/pages/studio/maps/[mapId].vue?macro=true";
import { default as google_45saved_45listsVIsSpU0wmnMeta } from "/opt/buildhome/repo/app/pages/studio/maps/import/google-saved-lists.vue?macro=true";
import { default as indexycNxSqkUPvMeta } from "/opt/buildhome/repo/app/pages/studio/maps/index.vue?macro=true";
import { default as mapsuURPjXAMT6Meta } from "/opt/buildhome/repo/app/pages/studio/maps.vue?macro=true";
import { default as indexM8sDM2L0pcMeta } from "/opt/buildhome/repo/app/pages/studio/payouts/details/index.vue?macro=true";
import { default as indextkXyKQRaWjMeta } from "/opt/buildhome/repo/app/pages/studio/payouts/index.vue?macro=true";
import { default as payouts3wynJTXLDpMeta } from "/opt/buildhome/repo/app/pages/studio/payouts.vue?macro=true";
import { default as indexvDIWGalCu1Meta } from "/opt/buildhome/repo/app/pages/studio/posts/index.vue?macro=true";
import { default as _91postId_93ZiWM3pMdmWMeta } from "/opt/buildhome/repo/app/pages/studio/posts/postId/[postId].vue?macro=true";
import { default as profilenqCjPLqrVGMeta } from "/opt/buildhome/repo/app/pages/studio/profile.vue?macro=true";
import { default as supporters4vnK3UZ1SlMeta } from "/opt/buildhome/repo/app/pages/studio/supporters.vue?macro=true";
import { default as studioXoyZtVsn2aMeta } from "/opt/buildhome/repo/app/pages/studio.vue?macro=true";
import { default as _91_46_46_46slug_93xAZGTs3jz1Meta } from "/opt/buildhome/repo/app/pages/updates/[...slug].vue?macro=true";
import { default as indexrMn6jcflTaMeta } from "/opt/buildhome/repo/app/pages/updates/index.vue?macro=true";
import { default as _91handle_93vPEAvpB6rgMeta } from "~/pages/creators/[handle].vue?macro=true";
import { default as component_45stubQsWyElFHYiMeta } from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.13.2_eslint@8.57.1_rollup@4.34.6_sass@1.84.0_typescript@5.7.3_vite@6.1.0_vue-tsc@2.2.0/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubQsWyElFHYi } from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.13.2_eslint@8.57.1_rollup@4.34.6_sass@1.84.0_typescript@5.7.3_vite@6.1.0_vue-tsc@2.2.0/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/opt/buildhome/repo/node_modules/.pnpm/@nuxt+content@2.13.4_nuxt@3.15.4_vue@3.5.13/node_modules/@nuxt/content/dist/runtime/pages/document-driven.vue")
  },
  {
    name: "account___en",
    path: "/account",
    meta: accountySftgjtTmBMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/account.vue")
  },
  {
    name: "atlas___en",
    path: "/atlas",
    meta: atlasDQWCsSZrWlMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/atlas.vue")
  },
  {
    name: "auth___en",
    path: "/auth",
    component: () => import("/opt/buildhome/repo/app/pages/auth.vue")
  },
  {
    name: "creators-handle___en",
    path: "/creators/:handle()",
    component: () => import("/opt/buildhome/repo/app/pages/creators/[handle].vue")
  },
  {
    name: "creators___en",
    path: "/creators",
    component: () => import("/opt/buildhome/repo/app/pages/creators/index.vue")
  },
  {
    name: "destinations-id___en",
    path: "/destinations/:id()",
    component: () => import("/opt/buildhome/repo/app/pages/destinations/[id].vue")
  },
  {
    name: "destinations___en",
    path: "/destinations",
    component: () => import("/opt/buildhome/repo/app/pages/destinations/index.vue")
  },
  {
    name: "explore___en",
    path: "/explore",
    component: () => import("/opt/buildhome/repo/app/pages/explore.vue")
  },
  {
    name: "forgot-password___en",
    path: "/forgot-password",
    component: () => import("/opt/buildhome/repo/app/pages/forgot-password.vue")
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/opt/buildhome/repo/app/pages/index.vue")
  },
  {
    name: "info-copyright___en",
    path: "/info/copyright",
    component: () => import("/opt/buildhome/repo/app/pages/info/copyright.vue")
  },
  {
    name: "info-creator-compensation___en",
    path: "/info/creator-compensation",
    component: () => import("/opt/buildhome/repo/app/pages/info/creator-compensation.vue")
  },
  {
    name: "info-creator-terms___en",
    path: "/info/creator-terms",
    component: () => import("/opt/buildhome/repo/app/pages/info/creator-terms.vue")
  },
  {
    name: "maps-mapId___en",
    path: "/maps/:mapId()",
    meta: _91mapId_93egFDOQJyVeMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/maps/[mapId].vue"),
    children: [
  {
    name: "maps-mapId-spots-spotId___en",
    path: "spots/:spotId()",
    component: () => import("/opt/buildhome/repo/app/pages/maps/[mapId]/spots/[spotId].vue")
  }
]
  },
  {
    name: "maps___en",
    path: "/maps",
    component: () => import("/opt/buildhome/repo/app/pages/maps/index.vue")
  },
  {
    name: "reset-password___en",
    path: "/reset-password",
    component: () => import("/opt/buildhome/repo/app/pages/reset-password.vue")
  },
  {
    name: "saved-maps___en",
    path: "/saved-maps",
    meta: saved_45mapsj5iBmKeJNmMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/saved-maps.vue")
  },
  {
    name: "saved-spots___en",
    path: "/saved-spots",
    meta: saved_45spotsPhpDosRhd9Meta || {},
    component: () => import("/opt/buildhome/repo/app/pages/saved-spots.vue")
  },
  {
    name: "studio___en",
    path: "/studio",
    meta: studioXoyZtVsn2aMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/studio.vue"),
    children: [
  {
    name: "studio-admin-articles___en",
    path: "admin/articles",
    component: () => import("/opt/buildhome/repo/app/pages/studio/admin/articles/index.vue")
  },
  {
    name: "studio-admin-categories___en",
    path: "admin/categories",
    component: () => import("/opt/buildhome/repo/app/pages/studio/admin/categories/index.vue")
  },
  {
    name: "studio-admin-creator-collab___en",
    path: "admin/creator-collab",
    component: () => import("/opt/buildhome/repo/app/pages/studio/admin/creator-collab/index.vue")
  },
  {
    name: "studio-admin-creators-id___en",
    path: "admin/creators/:id()",
    component: () => import("/opt/buildhome/repo/app/pages/studio/admin/creators/[id].vue")
  },
  {
    name: "studio-admin-creators___en",
    path: "admin/creators",
    component: () => import("/opt/buildhome/repo/app/pages/studio/admin/creators/index.vue")
  },
  {
    name: "studio-admin-destinations___en",
    path: "admin/destinations",
    component: () => import("/opt/buildhome/repo/app/pages/studio/admin/destinations/index.vue")
  },
  {
    name: "studio-admin___en",
    path: "admin",
    component: () => import("/opt/buildhome/repo/app/pages/studio/admin/index.vue")
  },
  {
    name: "studio-creator-faq___en",
    path: "creator-faq",
    component: () => import("/opt/buildhome/repo/app/pages/studio/creator-faq/index.vue")
  },
  {
    name: mapsuURPjXAMT6Meta?.name,
    path: "maps",
    component: () => import("/opt/buildhome/repo/app/pages/studio/maps.vue"),
    children: [
  {
    name: _91mapId_93aGHA4LCbarMeta?.name,
    path: ":mapId()",
    component: () => import("/opt/buildhome/repo/app/pages/studio/maps/[mapId].vue"),
    children: [
  {
    name: "studio-maps-mapId___en",
    path: "",
    meta: index9jfKR8Ph8kMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/studio/maps/[mapId]/index.vue")
  },
  {
    name: "studio-maps-mapId-spots-spotId___en",
    path: "spots/:spotId()",
    component: () => import("/opt/buildhome/repo/app/pages/studio/maps/[mapId]/spots/[spotId].vue")
  }
]
  },
  {
    name: "studio-maps-import-google-saved-lists___en",
    path: "import/google-saved-lists",
    component: () => import("/opt/buildhome/repo/app/pages/studio/maps/import/google-saved-lists.vue")
  },
  {
    name: "studio-maps___en",
    path: "",
    component: () => import("/opt/buildhome/repo/app/pages/studio/maps/index.vue")
  }
]
  },
  {
    name: payouts3wynJTXLDpMeta?.name,
    path: "payouts",
    component: () => import("/opt/buildhome/repo/app/pages/studio/payouts.vue"),
    children: [
  {
    name: "studio-payouts-details___en",
    path: "details",
    component: () => import("/opt/buildhome/repo/app/pages/studio/payouts/details/index.vue")
  },
  {
    name: "studio-payouts___en",
    path: "",
    component: () => import("/opt/buildhome/repo/app/pages/studio/payouts/index.vue")
  }
]
  },
  {
    name: "studio-posts___en",
    path: "posts",
    component: () => import("/opt/buildhome/repo/app/pages/studio/posts/index.vue")
  },
  {
    name: "studio-posts-postId-postId___en",
    path: "posts/postId/:postId()",
    component: () => import("/opt/buildhome/repo/app/pages/studio/posts/postId/[postId].vue")
  },
  {
    name: "studio-profile___en",
    path: "profile",
    component: () => import("/opt/buildhome/repo/app/pages/studio/profile.vue")
  },
  {
    name: "studio-supporters___en",
    path: "supporters",
    component: () => import("/opt/buildhome/repo/app/pages/studio/supporters.vue")
  }
]
  },
  {
    name: "updates-slug___en",
    path: "/updates/:slug(.*)*",
    component: () => import("/opt/buildhome/repo/app/pages/updates/[...slug].vue")
  },
  {
    name: "updates___en",
    path: "/updates",
    component: () => import("/opt/buildhome/repo/app/pages/updates/index.vue")
  },
  {
    name: "profile___en",
    path: "/@:handle",
    component: () => import("~/pages/creators/[handle].vue")
  },
  {
    name: component_45stubQsWyElFHYiMeta?.name,
    path: "/search",
    component: component_45stubQsWyElFHYi
  },
  {
    name: component_45stubQsWyElFHYiMeta?.name,
    path: "/app/:pathMatch(.*)",
    component: component_45stubQsWyElFHYi
  },
  {
    name: component_45stubQsWyElFHYiMeta?.name,
    path: "/app/account",
    component: component_45stubQsWyElFHYi
  },
  {
    name: component_45stubQsWyElFHYiMeta?.name,
    path: "/app/bookmarks",
    component: component_45stubQsWyElFHYi
  },
  {
    name: component_45stubQsWyElFHYiMeta?.name,
    path: "/studio/bank",
    component: component_45stubQsWyElFHYi
  },
  {
    name: component_45stubQsWyElFHYiMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubQsWyElFHYi
  }
]