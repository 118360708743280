import type { Network } from '../types'

export const email: Network = {
  name: 'Email',
  shareUrl: 'mailto:?body=[u]',
  args: {
    title: '&subject=[t]',
  },
  icon: {
    // iconify: ic:round-mail
    viewBox: '0 0 24 24',
    path: 'M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2m-.4 4.25l-6.54 4.09c-.65.41-1.47.41-2.12 0L4.4 8.25a.85.85 0 1 1 .9-1.44L12 11l6.7-4.19a.85.85 0 1 1 .9 1.44',
  },
  color: '#7e7e7e',
}
