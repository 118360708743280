const isOpen = ref(false)
const currentComp = ref<string | null>('')
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const currentCompProps = ref<any>({})
const isFullscreen = ref(false)

export const useDialog = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const openDialog = (compName: string, compProps?: any) => {
    currentComp.value = compName
    currentCompProps.value = compProps
    isOpen.value = true
  }

  const closeDialog = () => {
    isOpen.value = false
    currentComp.value = null
    currentCompProps.value = {}
  }

  const setFullscreen = (value: boolean) => {
    isFullscreen.value = value
  }

  return {
    isOpen,
    currentComp,
    currentCompProps,
    openDialog,
    closeDialog,
    isFullscreen,
    setFullscreen
  }
}
